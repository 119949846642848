function ajaxSettingsConstructor(url, requestType, sendData) {
    return {
        url: url,
        method: requestType,
        headers: {
            'content-type': 'application/json;charset=utf-8'
        },
        data: sendData,
        datatype: 'json'
    }
}

export default ajaxSettingsConstructor;