import React, {useEffect, useRef, useState} from 'react';
import './OrderInfoPage.scss';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { appNavigationInfo } from "../../redux/reducers/appNavigation";
import {changePage, updateIsLoading} from "../../redux/actions";
import * as $ from "jquery";
import ajaxSettingsConstructor from "../../helpers/ajaxSettingsConstructor";
import hostname from "../../configs/hostName";


const OrderInfoPage = (props) => {
    const dispatch = useDispatch();

    const pageStatus = useSelector(state => state.appNavigation.currentPage.name === appNavigationInfo.orderInfoPage.name, shallowEqual);
    const orderId = useSelector(state => state.appNavigation.query, shallowEqual);
    const prevPageStatus = usePrevious(pageStatus);
    const [success, setSuccess] = useState(null);

    useEffect(() => {
        if (prevPageStatus !== pageStatus) {
            dispatch(updateIsLoading(false));
            checkPayment();
        }
    })

    function usePrevious(value) {
        const ref = useRef();
        useEffect(() => {
            ref.current = value;
        });
        return ref.current;
    }

    function checkPayment() {
        if (!orderId || success === true) return;
        const ajaxSettings = ajaxSettingsConstructor(
            `${hostname}/api/onPaidOrder`,
            "GET",
            {order_id: orderId},
        );

        $.ajax(ajaxSettings)
            .then(data => {
                if (data && data.meta && data.meta.code && data.meta.code === 'RESP_OKAY') {
                    setSuccess(true);
                }
            })
            .fail((data) => {
                console.log(data);
            })
            .catch(data => {
                console.log(data);
            });
    }

    return (
        <div className={`OrderInfoPage page ${pageStatus ? 'active' : ''}`}  id={'OrderInfoPage'}>
            {success === true && <>
                <img className="tick-icon" alt="" src={require("../../assets/images/orderinfopage/tick.png")} />
                <div className="title">感謝您的訂購！</div>
                <div>你的預約編號是：{orderId}</div>
                <div>為確保安裝順利，請在你預計安放濾芯的位置拍一張照片並發送到 WhatsApp <a href="https://api.whatsapp.com/send?phone=85251340000&text=" className={"whatsapp"} target={"_blank"}><img alt="" src={require("../../assets/images/orderinfopage/whatsapp.png")} width={30} /> 51340000</a> 讓我們安裝人員預先確認及作最好的準備。</div>

                <div className="sample-images">
                    <   div>例子 ：</div>
                    <img alt="" src={require("../../assets/images/orderinfopage/sample1.png")} />
                    <img alt="" src={require("../../assets/images/orderinfopage/sample2.png")} />
                </div>
            </>}
            {success === false && <div>
                <img className="tick-icon" alt="" src={require("../../assets/images/orderinfopage/exclamation-mark.png")} />
                <p>付款尚未被確認，請重新整理頁面或與客戶服務人員聯絡！</p>
            </div>}
            {success === null && <div>
                <p>請稍候，我們正在確認付款結果</p>
            </div>}
        </div>
    )
}

export default OrderInfoPage;
