import React from 'react';
import { useSelector, shallowEqual } from 'react-redux';

import Loadable from 'react-loadable';
// import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import { CSSTransitionGroup as ReactCSSTransitionGroup } from 'react-transition-group'
import { BallSpinFadeLoader } from "react-pure-loaders";
import './Main.scss';
import BottomBar from '../components/BottomBar/BottomBar';
import OrderInfoPage from './OrderInfoPage/OrderInfoPage';
import * as $ from "jquery";

const BookingPage = Loadable({
    loader: () => import("./BookingPage/BookingPage"),
    loading: () => null,
    modules: ["bookingPageChunk"],
});

const MainContainer = (props) => {
    const isLoading = useSelector(state => state.appNavigation.isLoading, shallowEqual);


    return (
        <div id="Main" className="Main">
            {/* <div><img alt="" src={require("../assets/images/bookingpage/topBanner.png")} className="top-banner" /></div> */}
            <div><img alt="" src={require("../assets/images/bookingpage/hkws_banner@2x.png")} className="top-banner" /></div>
            <BookingPage />
            <OrderInfoPage />
            <BottomBar />
            <img alt="" id="scroll-to-top-icon" className="scroll-to-top-icon" src={require("../assets/images/main/arrow-circle-up.svg")} onClick={() => {
                $(`html, body`).animate({ scrollTop: 0 }, { duration: 500, delay: 0 });
            }} />
            <ReactCSSTransitionGroup
                transitionName="popup-loading"
                transitionAppear={true}
                transitionAppearTimeout={1000}
                transitionEnter={false}
                transitionLeave={false}>
                {isLoading &&
                    <div className="loading-container loading-active">
                        <div className="loading">
                            <BallSpinFadeLoader
                                color={'#41B6B6'}
                                loading={true}
                            />
                        </div>
                    </div>
                }
            </ReactCSSTransitionGroup>
        </div>
    )
}

export default MainContainer;