export default function fetchSettingsConstructor(method, data, options = {}) {
    const DEFAULT_MODE = 'cors';
    const DEFAULT_HEADERS = {
      'Content-Type': 'application/json;charset=utf-8'
    };
    return {
        method,
        mode: options.mode || DEFAULT_MODE,
        headers: options.headers || DEFAULT_HEADERS,
        body: data
    }
}