export const CHANGE_LANGUAGE = "CHANGE_LANGUAGE";
export const SET_DISABLED_MODE = "SET_DISABLED_MODE";

export function changeLanguage(newLanguage = "eng") {
    return {
        type: CHANGE_LANGUAGE,
        newLanguage,
    };
}

export function setDisabledModeState(state = false) {
    return {
        type: SET_DISABLED_MODE,
        state,
    };
}
