import { POP_NAV_STACK, PUSH_NAV_STACK, UPDATE_API_IS_LOADING, UPDATE_IS_LOADING, UPDATE_QUERY, UPDATE_IS_MAP_PROCESSING } from "../actions";

export const appNavigationInfo = {
    bookingPage: {
        name: "bookingPage",
        subCategory: {},
    },

    orderInfoPage: {
        name: "orderInfoPage",
        subCategory: {},
    },
};

export function appNavigation(
    state = {
        currentPage: appNavigationInfo.bookingPage,
        samePageClick: 0,
        subCategory: "",
        query: '',
        navStack: [],
        isLoading: false,
        isApiLoading: false,
        isMapProcessing: false
    },
    action,
) {
    let stateUpdate = {};
    let navStack = state.navStack;
    switch (action.type) {
        case PUSH_NAV_STACK:
            stateUpdate = {
                currentPage: action.destinationPage,
                samePageClick:
                    state.samePageClick
                    + (state.currentPage.name === action.destinationPage.name && state.subCategory === action.subCategory ? 1 : 0),
                subCategory: action.subCategory,
                query: action.query ? action.query : "",
            };
            if (
                action.destinationPage === "bookingPage"
                || action.destinationPage === ""
            ) {
                stateUpdate.navStack = [];
            } else if (action.destinationPage.name !== state.currentPage.name || action.subCategory !== state.subCategory || action.query !== state.query) {
                if (navStack.length > 10) {
                    navStack.splice(1, 1);
                }
                navStack.push({
                    currentPage: {...state.currentPage},
                    subCategory: state.subCategory,
                    query: state.query,
                });
                stateUpdate.navStack = navStack;
            }
            return Object.assign({}, state, stateUpdate);
        case POP_NAV_STACK:
            const lastStack = navStack.pop();
            stateUpdate = {
                currentPage: {...lastStack.currentPage},
                subCategory: lastStack.subCategory,
                query: lastStack.query,
                navStack: navStack,
            };
            return Object.assign({}, state, stateUpdate);
        case UPDATE_IS_LOADING:
            return Object.assign({}, state, {
                isLoading: action.isLoading,
            });
        case UPDATE_API_IS_LOADING:
            return Object.assign({}, state, {
                isApiLoading: action.isApiLoading,
            });
        case UPDATE_QUERY:
            return Object.assign({}, state, {
                query: action.query,
            });
        case UPDATE_IS_MAP_PROCESSING:
            return Object.assign({}, state, {
                isMapProcessing: action.isMapProcessing,
            });
        default:
            return state;
    }
}
