import {appInformation} from "./appInformation";
import {appNavigation} from "./appNavigation";
import {appKeyboard} from "./appKeyboard";
import {appData} from "./appData";

import {combineReducers} from "redux";

export const appState = combineReducers({
    appInformation,
    appNavigation,
    appKeyboard,
    appData,
});
