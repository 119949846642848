import {
    CHANGE_KEYBOARD_TYPE,
    DISABLE_SCREEN_KEYBOARD,
    ENABLE_SCREEN_KEYBOARD,
    HANDLE_INPUT,
    TOGGLE_HANZI_RESULT,
} from "../actions";

export function appKeyboard(
    state = {
        isEnabled: false,
        inputNode: null,
        currentValue: "",
        keyboardType: "en",
        showHanziResult: true,
    },
    action,
) {
    switch (action.type) {
        case ENABLE_SCREEN_KEYBOARD:
            return Object.assign({}, state, {
                inputNode: action.inputNode,
                isEnabled: true,
                showHanziResult: true,
            });
        case DISABLE_SCREEN_KEYBOARD:
            if (state.inputNode) {
                state.inputNode.value = "";
            }
            return Object.assign({}, state, {
                isEnabled: false,
                inputNode: null,
                currentValue: "",
                showHanziResult: true,
                keyboardType: "latin",
            });
        case HANDLE_INPUT:
            return Object.assign({}, state, {
                currentValue: action.query,
            });
        case CHANGE_KEYBOARD_TYPE:
            return Object.assign({}, state, {
                keyboardType: action.keyboardType,
                showHanziResult: true,
            });
        case TOGGLE_HANZI_RESULT:
            return Object.assign({}, state, {
                showHanziResult: action.on,
            });
        default:
            return state;
    }
}
