import {CHANGE_LANGUAGE} from "../actions";
import {SET_DISABLED_MODE} from "../actions/appInformation";

export function appInformation(
    state = {
        currentLanguage: "en",
        isDisabledMode: false
    },
    action,
) {
    switch (action.type) {
        case CHANGE_LANGUAGE:
            return Object.assign({}, state, {
                currentLanguage: action.newLanguage,
            });
        case SET_DISABLED_MODE:
            return Object.assign({}, state, {
                isDisabledMode: action.state,
            });
        default:
            return state;
    }
}
