
const SAVE_BANNERS = "SAVE_BANNERS";

const saveBanners = banners => {
  return {
    type: SAVE_BANNERS,
    banners,
  };
};

const SAVE_SERVICES = "SAVE_SERVICES";

const saveServices = services => {
  return {
    type: SAVE_SERVICES,
    services,
  };
};


const SAVE_REDEEMS = "SAVE_REDEEMS";

const saveRedeems = redeems => {
  return {
    type: SAVE_REDEEMS,
    redeems,
  };
};

const SAVE_AREA= "SAVE_AREA";

const saveArea = area => {
  return {
    type: SAVE_AREA,
    area,
  };
};

const SAVE_SETTINGS = "SAVE_SETTINGS";

const saveSettings = settings => {
  return {
    type: SAVE_SETTINGS,
    settings,
  };
};

export const appDataActions = {

  SAVE_BANNERS,
  SAVE_SERVICES,
  SAVE_REDEEMS,
  SAVE_AREA,
  SAVE_SETTINGS,
};

export const appDataActionCreators = {
  saveBanners,
  saveServices,
  saveRedeems,
  saveArea,
  saveSettings,
};

export const appDataPostActions = {
  // processWashrooms
};
