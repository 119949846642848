import React from "react";
import ReactDOM from "react-dom";
import { createStore, applyMiddleware, compose } from "redux";
import thunkMiddleWare from "redux-thunk";
// import { createLogger } from "redux-logger";
import { Provider } from "react-redux";
import { appState } from "./redux/reducers";
import AppContainer from "./AppContainer";
import { unregister as unregisterServiceWorker } from './registerServiceWorker'

import "./index.css";
import ErrorBoundary from "./components/ErrorBoundary/ErrorBoundary";
//let loggerMiddleware = createLogger(); // redux log
const composeEnhancers =
  typeof window === "object" && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        // Specify extension’s options like name, actionsBlacklist, actionsCreators,
        // serialize...
      })
    : compose;

const enhancer = composeEnhancers(
  applyMiddleware(thunkMiddleWare) //  applyMiddleware(thunkMiddleWare, loggerMiddleware) // redux log
);
// other store enhancers if any
// );

let store = createStore(appState, enhancer);

ReactDOM.render(
  <Provider store={store}>
      <ErrorBoundary>
        <AppContainer />
      </ErrorBoundary>
  </Provider>,
  document.getElementById("root")
);

unregisterServiceWorker();
