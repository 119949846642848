export const CHANGE_PAGE = "CHANGE_PAGE";

export function changePage(
    destinationPage = "bookingPage",
    subCategory = null,
    query = "",
) {
    return function (dispatch) {
        dispatch(updateIsLoading(true));
        console.log("Change Page");
        setTimeout(() => {
            dispatch(pushNavStack(destinationPage, subCategory, query));
        }, 1);
    };
}

export const BACK_PAGE = "BACK_PAGE";

export function backPage() {
    return function (dispatch) {
        dispatch(updateIsLoading(true));
        console.log("Back Page");
        setTimeout(() => {
            dispatch(popNavStack());
        }, 1);
    };
}

export const PUSH_NAV_STACK = "PUSH_NAV_STACK";

export function pushNavStack(
    destinationPage = "bookingPage",
    subCategory = null,
    query = "",
) {
    console.log("PUSH NAV STACK");
    return {
        type: PUSH_NAV_STACK,
        destinationPage,
        subCategory,
        query,
    }
}

export const POP_NAV_STACK = "POP_NAV_STACK";

export function popNavStack() {
    console.log("POP NAV STACK");
    return {type: POP_NAV_STACK};
}

export const UPDATE_IS_LOADING = "UPDATE_IS_LOADING";

export function updateIsLoading(isLoading) {
    console.log("Update Is Loading: " + isLoading);
    return {type: UPDATE_IS_LOADING, isLoading};
}

export const UPDATE_API_IS_LOADING = "UPDATE_API_IS_LOADING";

export function updateApiIsLoading(isApiLoading) {
    console.log("Update API Is Loading: " + isApiLoading);
    return {type: UPDATE_API_IS_LOADING, isApiLoading};
}

export const UPDATE_QUERY = "UPDATE_QUERY";

export function updateQuery(query) {
    return {type: UPDATE_QUERY, query};
}

export const UPDATE_IS_MAP_PROCESSING = "UPDATE_IS_MAP_PROCESSING";

export function updateIsMapProcessing(isMapProcessing) {
    return {type: UPDATE_IS_MAP_PROCESSING, isMapProcessing};
}
