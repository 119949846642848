export const ENABLE_SCREEN_KEYBOARD = "ENABLE_SCREEN_KEYBOARD";

export function enableScreenKeyboard(inputNode) {
    return {
        type: ENABLE_SCREEN_KEYBOARD,
        inputNode,
    };
}

export const DISABLE_SCREEN_KEYBOARD = "DISABLE_SCREEN_KEYBOARD";

export function disableScreenKeyboard() {
    return {
        type: DISABLE_SCREEN_KEYBOARD,
    };
}

export const HANDLE_INPUT = "HANDLE_INPUT";

export function handleInput(query) {
    return {
        type: HANDLE_INPUT,
        query,
    };
}

export const CHANGE_KEYBOARD_TYPE = "CHANGE_KEYBOARD_TYPE";

export function changeKeyboardType(keyboardType) {
    return {
        type: CHANGE_KEYBOARD_TYPE,
        keyboardType,
    };
}

export const TOGGLE_HANZI_RESULT = "TOGGLE_HANZI_RESULT";

export function toggleHanziResult(on) {
    return {
        type: TOGGLE_HANZI_RESULT,
        on,
    };
}
