import { connect } from "react-redux";
import { App } from "./App";
import {appDataActionCreators, changePage} from "./redux/actions";
import {appNavigationInfo} from "./redux/reducers/appNavigation";

const mapStateToProps = state => {
    return Object.assign(
        {},
        {
            appNavigation: state.appNavigation,
            appInformation: state.appInformation,
            appData: state.appData,
        },
    );
};

const mapDispatchToProps = dispatch => {
    return {
      saveBanners: banners => dispatch(appDataActionCreators.saveBanners(banners)),
        saveServices: services => dispatch(appDataActionCreators.saveServices(services)),
        saveRedeems: redeems => dispatch(appDataActionCreators.saveRedeems(redeems)),
        saveArea: area => dispatch(appDataActionCreators.saveArea(area)),
        saveSettings: settings => dispatch(appDataActionCreators.saveSettings(settings)),
        redirectOrderInfo: orderId => dispatch(changePage(appNavigationInfo.orderInfoPage, null, orderId))
    };
};

const AppContainer = connect(
    mapStateToProps,
    mapDispatchToProps,
)(App);

export default AppContainer;
