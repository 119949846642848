import { appDataActions } from "../actions";

const {
    SAVE_BANNERS,
    SAVE_SERVICES,
    SAVE_REDEEMS,
    SAVE_AREA,
    SAVE_SETTINGS,
} = appDataActions;

export const appData = (
    state = {
        banners: [],
        services: [],
        redeems: [],
        area: [],
        settings: [],
    },
    action,
) => {
    switch (action.type) {
        case SAVE_BANNERS:
            return Object.assign({}, state, {
                banners: action.banners,
            });
        case SAVE_SERVICES:
            return Object.assign({}, state, {
                services: action.services,
            });
        case SAVE_REDEEMS:
            return Object.assign({}, state, {
                redeems: action.redeems,
            });
        case SAVE_AREA:
            return Object.assign({}, state, {
                area: action.area,
            });
        case SAVE_SETTINGS:
            return Object.assign({}, state, {
                settings: action.settings,
            });
        default:
            return state;
    }
};
