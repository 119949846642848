/* global stopReload */
import React from 'react';
// import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import { CSSTransitionGroup as ReactCSSTransitionGroup } from 'react-transition-group'
import { BallSpinFadeLoader } from "react-pure-loaders";
import { Modal } from '@material-ui/core';
import {getBanners, getServices, getRedeems, getArea, getSettings} from "./helpers/apiHelper";

import './App.scss';
import Main from "./containers/Main";

export class App extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isDisabledMode: false,
            dataInitialized: false,
            updateInProgress: false,
        };
        let query = window.location.search
            ? window.location.search.split("?")[1].split("&")
            : null;
        let orderId;

        query =
            query &&
            query.map(a => {
                var searchQuery = [];
                searchQuery[0] = a.split("=")[0];
                searchQuery[1] = a.split("=")[1];
                return searchQuery;
            });
        if (query) {
            orderId = query.find(a => a[0] === "order_id");
            if (orderId && orderId[1]) {
                orderId = orderId[1];
                this.props.redirectOrderInfo(orderId);
            }
        }

        this.collectClickDataInterval = null;
    };

    componentDidMount = () => {
        this.onLoad();
        stopReload();
        window.addEventListener("contextmenu", e => e.preventDefault());
        window.addEventListener('scroll', this.scrollFunction);
        this.updateBannersData();
        this.updateServiceData();
        this.updateRedeemData();
        this.updateAreaData();
        this.updateSettings();

    }

    async updateBannersData() {
        let banners = await getBanners();
        this.props.saveBanners(banners);
    }

    async updateServiceData() {
        let services = await getServices();
        this.props.saveServices(services);
    }

    async updateRedeemData() {
        let redeems = await getRedeems();
        this.props.saveRedeems(redeems);
    }

    async updateAreaData() {
        let area = await getArea();
        this.props.saveArea(area);
    }

    async updateSettings() {
        let settings = await getSettings();
        this.props.saveSettings(settings);
    }


    scrollFunction = () => {
        if(document.getElementById('scroll-to-top-icon')){
            if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
                document.getElementById('scroll-to-top-icon').style.display = "block";
            }else{
                document.getElementById('scroll-to-top-icon').style.display = "none";

            }
        }
    }
    componentWillUnmount() {
        if (this.collectClickDataInterval) {
            clearInterval(this.collectClickDataInterval);
            this.collectClickDataInterval = null;
        }
    }

    async onLoad() {
        if (!this.state.dataInitialized) {
            this.setState({ dataInitialized: true });
        }
    }

    render() {
        // console.log(this.props);
        return (
            <>
                <Modal open={!this.state.dataInitialized} hideBackdrop closeAfterTransition>
                    <div className="app-loading">
                        <ReactCSSTransitionGroup
                            transitionName="popup-loading"
                            transitionAppear={true}
                            transitionAppearTimeout={1000}
                            transitionEnter={false}
                            transitionLeave={false}>
                            <div className="loading-container loading-active">
                                <div className="loading">
                                    <BallSpinFadeLoader
                                        color={'#41B6B6'}
                                        loading={true}
                                    />
                                </div>
                            </div>
                        </ReactCSSTransitionGroup>
                    </div>
                </Modal>
                <div className={"App"} id='App'  style={{ opacity: this.state.dataInitialized ? 1 : 0 }} >
                    <Main/>
                </div>
            </>
        );
    }
}

