import fetchOptionConstructor from "./fetchOptionConstructor";
import hostname from "../configs/hostName";

export async function getBanners() {
  try {
    let response = await fetch(`${hostname}/api/banner`, fetchOptionConstructor('GET'))
    if (response.ok) {
      const data = await response.json();
      if (data.meta.code === 'RESP_OKAY') {

        let banners = data.data.banner;
        banners.forEach(banner => {
          banner.path = hostname +'/'+ banner.path;
        });
        return data.data.banner;
      } else {
        console.error('fetch banners list data error', data)
      }
    } else {
      console.error('fetch banners not ok', response)
    }
  } catch (e) {
    console.error('fetch banners error', e)
  }
}

export async function getServices() {
  try {
    let response = await fetch(`${hostname}/api/service`, fetchOptionConstructor('GET'))
    if (response.ok) {
      const data = await response.json();
      if (data.meta.code === 'RESP_OKAY') {

        let services = data.data.service;
        services.forEach(service => {
          service.image = hostname +'/'+ service.image;
        });
        return data.data.service;
      } else {
        console.error('fetch Service list data error', data)
      }
    } else {
      console.error('fetch Service not ok', response)
    }
  } catch (e) {
    console.error('fetch Service error', e)
  }
}
export async function getRedeems() {
  try {
    let response = await fetch(`${hostname}/api/redeem`, fetchOptionConstructor('GET'))
    if (response.ok) {
      const data = await response.json();
      if (data.meta.code === 'RESP_OKAY') {

        let redeems = data.data.redeem;
        redeems.forEach(redeem => {
          redeem.image = hostname +'/'+ redeem.image;
        });
        return data.data.redeem;
      } else {
        console.error('fetch Redeems list data error', data)
      }
    } else {
      console.error('fetch Redeems not ok', response)
    }
  } catch (e) {
    console.error('fetch Redeems error', e)
  }
}

export async function getArea() {
  try {
    let response = await fetch(`${hostname}/api/area`, fetchOptionConstructor('GET'))
    if (response.ok) {
      const data = await response.json();
      if (data.meta.code === 'RESP_OKAY') {
        return data.data.area;
      } else {
        console.error('fetch Area list data error', data)
      }
    } else {
      console.error('fetch Area not ok', response)
    }
  } catch (e) {
    console.error('fetch Area error', e)
  }
}

export async function getSettings() {
  try {
    let response = await fetch(`${hostname}/api/settings`, fetchOptionConstructor('GET'))
    if (response.ok) {
      const data = await response.json();
      if (data.meta.code === 'RESP_OKAY') {
        return data.data.settings;
      } else {
        console.error('fetch setting data error', data)
      }
    } else {
      console.error('fetch setting not ok', response)
    }
  } catch (e) {
    console.error('fetch setting error', e)
  }
}

export async function getUnuseDate() {
  try {
    let response = await fetch(`${hostname}/api/area`, fetchOptionConstructor('GET'))
    if (response.ok) {
      const data = await response.json();
      if (data.meta.code === 'RESP_OKAY') {
        return data.data.area;
      } else {
        console.error('fetch Area list data error', data)
      }
    } else {
      console.error('fetch Area not ok', response)
    }
  } catch (e) {
    console.error('fetch Area error', e)
  }
}
