import React from 'react';
import './BottomBar.scss';

const BottomBar = (props) => {
    const year = new Date().getFullYear();

    return (
        <div className={`BottomBar`}>
            <div className="row">
                <div className="col">
                    <div className="title">商店</div>
                    <div><a href="https://www.hk-water.com/about.html">關於我們</a></div>
                    <div><a href="https://www.hk-water.com/contact.html">聯絡我們</a></div>
                    <div><a href="https://www.hk-water.com/blog/index">最新消息</a></div>
                </div>
                <div className="col"> 
                <div className="title">資訊</div>
                    {/* <div>訂單狀態</div> */}
                    <div><a href="https://www.hk-water.com/#/">常見問題</a></div>
                    <div><a href="https://www.hk-water.com/privacy-policy/">私隱政策</a></div>
                    <div><a href="https://www.hk-water.com/contact.html/">意見回饋</a></div>
                </div>
                <div className="col">
                    <div className="title">幫助</div>
                    <div><a href="https://www.hk-water.com/terms-of-use/">使用條款</a></div>
                    <div><a href="https://www.hk-water.com/warranty/">保用及維修服務政策</a></div>
                    <div><a href="https://www.hk-water.com/return/">退貨、退款或換貨服務</a></div>
                    <div><a href="https://www.hk-water.com/delivery/">送貨安排</a></div>
                </div>
                <div className="col">
                    <div className="title">付款方式</div>
                    <div title="PayPal 如何運作" onClick={()=>
                        {window.open('https://www.paypal.com/hk/webapps/mpp/paypal-popup?locale.x=zh_HK','WIPaypal','toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=yes, resizable=yes, width=1060, height=700');
                         return false;}} ><img alt="PayPal 立即買" src="https://www.paypalobjects.com/webstatic/mktg/logo/PP_AcceptanceMarkTray-NoDiscover_243x40.png"/></div>
                    <div><img alt="" src="https://www.hk-water.com/media/wysiwyg/payment_1.png"/></div>
                    <div><span id="siteseal">
                            <script type="text/javascript" src="https://seal.godaddy.com/getSeal?sealID=j2Qu8GZhLAWnpz6i7nyCdU3ZRnptDf6XuZNuxHIK3kIbFJaUOQxeBUNT6AQX" async=""></script>
                            <img src="https://seal.godaddy.com/images/3/en/siteseal_gd_3_h_l_m.gif" onClick={()=>window.verifySeal()} alt="SSL site seal - click to verify"></img>
                        </span></div>
                    <p>我們的網站採用256位元SSL加密裝置，令顧客購物更為放心。</p>
                </div>
            </div>

            <div className="copyright">© {year} HKWS. All Rights Reserved.  <a href="http://www.lbsgroup.com.hk/?lang=tc/" rel="noopener noreferrer" target="_blank"> LBS Group </a>  集團成員</div>
        </div>
    )
}

export default BottomBar;
